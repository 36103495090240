import styled from 'styled-components';
import { shade } from 'polished';

export const Button = styled.button`
  border-radius: 5px;
  padding: 10px;
  border: none;
  outline: 0;
  background-color: #2d8a07;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 0 10px #989898;
  margin: 0 5px 0;

  &:hover {
    background-color: ${shade(0.3, '#2d8a07')}; 
  }
`;