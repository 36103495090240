import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90%;
  width: 50%;
  background-color: #fff;
  border-radius: 5px;

  p {
      margin-top: 25px;
  }
`;

export const Logo = styled.img`
    margin-bottom: 50px;
`;

export const LinkContact = styled.a`
    margin-top: 25px;
    text-decoration:none; 
    color: #fff !important;
    background-color: #01e675;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 152px;
    height: 36px;
    font-size: 10pt;
`;