import React from 'react';
import InputMask from "react-input-mask";

import { InputPhone } from './styles';

export default function InputMaskPhone(props) {
    return (
        <InputMask mask={props.mask} alwaysShowMask value={props.value} onChange={props.onChange}>
            {(inputProps) => <InputPhone {...inputProps} type="tel"  />}
        </InputMask>
    );
}