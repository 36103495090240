import React from "react";

import { ManagerStepperBoxContent, ManagerStepperContainerMain } from "./styles";

import { useSelector } from "react-redux";

export default function ManagerStepper(props) {
  const { steps } = props;

  const stepperActive = useSelector(state => state.createAppReducer.stepper);
  return (
    <ManagerStepperContainerMain>
      <ManagerStepperBoxContent>{steps[stepperActive].component}</ManagerStepperBoxContent>
    </ManagerStepperContainerMain>
  );
}
