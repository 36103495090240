import styled from "styled-components";

export const InfoContainer = styled.div`
  background-color: #fff;
  display: flex;
  margin: 17px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 80vh;
  width: 90vw;
`;

export const ImgNoPhone = styled.div`
  height: 160px;
  width: 100%;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Title = styled.p`
    padding: 18px;
    text-align: justify;
    font-size: 14pt;
    font-weight: bold;
`;
