import styled from 'styled-components';



export const DescriptionFinish = styled.p`
    font-size: 12pt;
    text-align: justify;
    padding: 20px;
`;

export const ImgFinish = styled.div`
    height: 360px;
    width: 100%;
    display: flex;
    background-size:contain;
    background-position:center;
    background-repeat: no-repeat;
    background-image: url(${props => props.src});
`;