import React from "react";
import { NavBar } from "../NavBar";
import { TemplateBox } from './styles';

export default function TemplateSimple(props) {
  const { component } = props;
  return (
    <TemplateBox>
      <NavBar />
      {component}
    </TemplateBox>
  );
}
