import styled from 'styled-components';

export const InfoViewContainer = styled.div`
  height: 540px;
  display: flex;
  flex-direction: column;
`;

export const InfoViewTitleContent = styled.h4`
  font-size: 18pt;
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
`;

export const InfoViewDescriptionContent = styled.p`
  text-align: justify;
  margin: 0 20px;
`;

export const InfoViewLogoContent = styled.img`
  width: 26%;
  display: block;
  margin: auto;
`;