import styled from 'styled-components';
import { shade } from 'polished';

export const NavBar = styled.nav`
  background-color: #fff;
  display: flex;
  padding: 10px 100px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px #ccc;
`;

export const NavBarImg = styled.img`
  width: 150px;
`;

export const NavBarUl = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
`;

export const NavBarLi = styled.li`
  margin: 0 10px;
  cursor: pointer;
`;

export const NavBarA = styled.a`
  color: var(--main-color);
  font-weight: 600;
  font-size: 11pt;

  &:hover {
    color: ${shade(0.3, 'rgb(38, 68, 102)')};
  }
`;

export const NavBarButton = styled.button`
  padding: 10px 15px;
  background-color: #fff;
  color: var(--main-color);
  font-weight: 600;
  border: 2px solid;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  outline: 0;

  &:hover {
    background-color: var(--main-color);
    color: #fff;
  }
`;
