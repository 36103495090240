import React, { useRef, useEffect, useCallback, useState } from "react";
import { SketchPicker, CirclePicker } from "react-color";

import Grid from "@material-ui/core/Grid";

import ButtonStepper from "../../ButtonStepper";
import FooterOption from "../../FooterOption";
import SimulatorMobile from "../../SimulatorMobile";

import { changeStepper, changeColorAppDriver } from "../../../action/CreateApp";

import { useSelector, useDispatch } from "react-redux";

import {
  Container,
  ContainerColor,
  TitleColor,
  ContainerSideColor,
  DescriptionColor,
  ObservationColor,
  ContentColor,
  LabelColor,
  InputColor,
} from "./styles";
import { Divider, Typography } from "@material-ui/core";

export default function SimulatorMobileDriverView() {
  const [wasChange, setWasChange] = useState(true);
  const refSimulatorView = useRef();
  const stepperActive = useSelector((state) => state.createAppReducer.stepper);

  const { name, logo } = useSelector(
    (state) => state.createAppReducer.app.appDriver
  );

  const { mainColor, mainTextColor } =
    useSelector((state) => state.createAppReducer.app.appDriver.colors);

  const dispatch = useDispatch();

  function nextStepper(event) {
    event.preventDefault();

    dispatch(changeStepper(stepperActive + 1));
  }

  function prevStepper(event) {
    event.preventDefault();

    dispatch(changeStepper(stepperActive - 1));
  }

  function getElementInIframe(name, callback, empty) {
    const iframe = refSimulatorView.current.getIframe();
    if (iframe) {
      const elements = iframe.contentWindow.document.querySelectorAll(name);

      if (elements.length === 0 && empty) return empty();

      elements.forEach((item) => {
        callback(item);
      });
    }
  }

  const changeLogoPreview = useCallback((logo) => {
    if (logo == null || !(logo instanceof Blob)) return;

    let reader = new FileReader();

    reader.readAsDataURL(logo);

    reader.onloadend = function (e) {
      getElementInIframe(".logo-preview-app", (element) => {
        element.src = reader.result;
      });

      getElementInIframe(".logo-preview-app-background", (element) => {
        element.style.backgroundImage = `url(${reader.result})`;
      });
    };
  }, []);

  const changeColorPreview = useCallback(
    ({ mainColor, mainTextColor }) => {
      getElementInIframe(
        "#cssPreview",
        (element) => {
          element.innerHTML = `
            :root {
              --main-color: ${mainColor};
              --second-color: ${mainColor};
              --text-color-main: ${mainTextColor};
              --text-color-second: ${mainTextColor}
            }
          `;
        },
        () => {
          var style = document.createElement("style");

          style.setAttribute("id", "cssPreview");
          style.type = "text/css";
          style.innerHTML = `
            :root {
              --main-color: ${mainColor};
              --second-color: ${mainColor};
              --text-color-main: ${mainTextColor};
              --text-color-second: ${mainTextColor}
            }
          `;

          getElementInIframe("head", (head) => {
            head.appendChild(style);
          });
        }
      );
    },
    []
  );

  const updateSimulator = useCallback(() => {
    changeLogoPreview(logo);
    changeColorPreview({
      mainColor,
      mainTextColor,
    });
  }, [
    changeLogoPreview,
    changeColorPreview,
    logo,
    mainColor,
    mainTextColor,
  ]);

  useEffect(() => {
    updateSimulator();
  }, [updateSimulator]);

  return (
    <form>
      <Typography variant="h5" gutterBottom>
      Aplicativo Motorista
      </Typography>
      <Divider />
      <Container>
        <Grid item xs={3}>
        <div style={{display:"flex", justifyContent: "center"}}>
            <SimulatorMobile
              ref={refSimulatorView}
              links={[
                "/examples/driver/index.html",
                "/examples/driver/login.html",
                "/examples/driver/splash.html",
                "/examples/driver/menuDriver.html",
              ]}
              colors={{
                mainColor,
                mainTextColor,
              }}
              name={name}
              logo={logo}
              onLoadView={() => {
                updateSimulator();
              }}
            />
          </div>
            <ContentColor>
              <LabelColor>Cor principal</LabelColor>
              <InputColor 
                 onChange={({target}) => {
                  setWasChange(false)
                  dispatch(
                    changeColorAppDriver({
                      mainColor: target.value,
                      mainTextColor,
                    })
                  );
                }}
                value={mainColor}
                />
          </ContentColor>
          <ContentColor>
              <LabelColor>Cor texto principal</LabelColor>
              <InputColor 
                  onChange={({target}) => {
                    dispatch(
                      changeColorAppDriver({
                        mainColor,
                        mainTextColor: target.value,
                      })
                    );
                  }}
                  value={mainTextColor}
                />
          </ContentColor>
        </Grid>
      </Container>

      <FooterOption>
        <ButtonStepper value={wasChange} onClick={nextStepper}>Próximo</ButtonStepper>
        <ButtonStepper onClick={prevStepper}>Voltar</ButtonStepper>
      </FooterOption>
    </form>
  );
}
