import { createStore } from "redux";
import { persistStore } from 'redux-persist';

import  Reducers  from "../reducers";

export default () => {
  const store = createStore(
    Reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  const storePersist = persistStore(store);
  return { store, storePersist };
};
