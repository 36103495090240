import React from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { MenuStepperVertical } from "./styles";

import { useSelector } from "react-redux";

export default function MenuStepper(props) {
  const { steps } = props;

  const stepperActive = useSelector(state => state.createAppReducer.stepper);

  return (
    <MenuStepperVertical>
      <Stepper activeStep={stepperActive} orientation="vertical">
        {steps.map(({ label }, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </MenuStepperVertical>
  );
}
