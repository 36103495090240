import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
`;

export const ContainerColor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #cfcfcf;
  padding: 5px;
  margin: 10px 5px 0;
  border-radius: 5px;
  overflow: hidden;
`;

export const TitleColor = styled.p`
  font-family: sans-serif;
  font-size: 10pt;
  font-weight: 600;
  margin: -5px -5px 15px;
  background-color: #e6e6e6;
  width: 100%;
  text-align: center;
  padding: 10px 5px;
`;

export const ContainerSideColor = styled.div`
  display: flex;
  max-width: 100%;
  margin: 10px 0 0;
`;

export const DescriptionColor = styled.p`
  font-family: sans-serif;
  font-size: 10pt;
  font-weight: 600;
  margin: 10px 5px 15px;
`;

export const ObservationColor = styled.p`
  font-size: 12pt;
  color: red;
  margin: 10px;
`;

export const ContentColor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin:10px 20px;
`;

export const LabelColor = styled.label`
  color:#444;
  font-weight: 600;
`;

export const InputColor = styled.input.attrs(props => ({
  type: "color"
}))`

`;