import React from "react";
import Routes from "./routes/routes";

import "./App.css";

import returnStoreAndPersistor from "./store";

import { Provider } from "react-redux";


const { store } = returnStoreAndPersistor();


function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
