import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  CityViewTitleLeftContent,
  CityViewDescriptionLeftContent,
  ContainerCityViewList,
  ContainerSelects,
  CityViewList,
  CityViewItem,
} from "./styles";
import Select from "../../Select";
import swal from "sweetalert";

import ButtonStepper from "../../ButtonStepper";
import FooterOption from "../../FooterOption";

import { changeStepper, setListCities } from "../../../action/CreateApp";

import { useSelector, useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@material-ui/core";
import api from "../../../services/api";
import apiCommercial from "../../../services/apiCommercial";

export default function CityView() {
  const [amountCities, setAmountCities] = useState(0);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [idUf, setIdUf] = useState(0);
  const [ufs, setUfs] = useState([]);

  const { key } = useParams();

  const listCity = useSelector((state) => state.createAppReducer.app.cities);
  const stepperActive = useSelector((state) => state.createAppReducer.stepper);

  const dispatch = useDispatch();

  async function getStates() {
    const states = await api.get("/locality/state");
    setUfs(states.data.response);
  }

  async function getCities(idState) {
    const citiesApi = await api.get(`/locality/state/${idState}/cities`);
    setCities(citiesApi.data.response);
  }

  const getCustomer = useCallback(async () => {
    const { data } = await apiCommercial.get(`/${key}/get_customer`);
    setAmountCities(data.customer_form.cities_count ?? 0);
  }, [key]);

  useEffect(() => {
    getStates();
    getCustomer();
  }, [getCustomer]);

  function addNewCityItems() {

    if (listCity.length > 0) {
      const existsCiy = listCity.filter(item => item.id === (JSON.parse(city)).id);

      if (existsCiy.length > 0) return swal(
        "Esta cidade já foi adicionada",
        "Selecione uma cidade diferente",
        "warning"
      );
    }

    if (amountCities && listCity.length < amountCities) {
      dispatch(setListCities([...listCity, JSON.parse(city)]));
      setCity("");
      setIdUf(0);
    } else {
      return swal(
        "Numero de cidade(s) contratada(s) excedido",
        "Após finalizar o formulário entre em contato com o seu consultor comercial para implementar as demais cidades.",
        "warning"
      );
    }
  }

  function nextStepper(event) {
    event.preventDefault();

    if (listCity.length <= 0) {
      return swal("Informe as cidades de atuação", "", "warning");
    }
    
    // if (listCity.length < amountCities) {
    //   return swal(
    //     `Você ainda possui ${amountCities - listCity.length} cidade(s) contratada(s) para adicionar`,
    //     "",
    //     "warning"
    //   );
    // }

    dispatch(changeStepper(stepperActive + 1));
  }

  function prevStepper(event) {
    event.preventDefault();
    dispatch(changeStepper(stepperActive - 1));
  }

  return (
    <form>
      <CityViewTitleLeftContent>Cidades do seu App*</CityViewTitleLeftContent>
      <CityViewDescriptionLeftContent>
        Agora com muita atenção você escolherá a(s) {amountCities} cidade(s) que seu
        app atuará, caso pretenda adicionar mais cidades, escolha a quantidade
        contratada e depois fale com seu consultor comercial para implementar as
        demais cidades, importante lembrar que as cidades não podem ser
        substituídas posteriormente.
      </CityViewDescriptionLeftContent>

      <ContainerCityViewList>
        <legend>
          <h4>Estados e Cidades Disponíveis</h4>
        </legend>
        <ContainerSelects>
          <Select
            name="uf"
            label="Estado"
            value={idUf}
            onChange={(event) => {
              const dataUf = event.target.value;
              setIdUf(dataUf);
              getCities(dataUf);
            }}
            default={{ value: "0", label: "Selecione o Estado" }}
            options={ufs.map((uf) => ({ value: uf.id, label: uf.nome }))}
          />
          <Select
            name="city"
            label="Cidade"
            value={city}
            disabled={idUf === 0}
            onChange={(event) => {
              setCity(event.target.value);
            }}
            default={{ value: "0", label: "Selecione a Cidade" }}
            options={cities.map((city) => ({
              value: JSON.stringify(city),
              label: city.nome,
            }))}
          />
          <IconButton
            size="small"
            variant="contained"
            onClick={addNewCityItems}
            color="primary"
          >
            <Icon fontSize="large">add_circle</Icon>
          </IconButton>
        </ContainerSelects>
        <CityViewList active={listCity.length > 0}>
          {listCity.map((city, index) => {
            return (
              <CityViewItem key={index}>
                <span>{city.nome}</span>
                <Icon
                  onClick={() => {
                    dispatch(
                      setListCities(
                        listCity.filter((item, indexRemove) => {
                          return index !== indexRemove;
                        })
                      )
                    );
                  }}
                >
                  close
                </Icon>
              </CityViewItem>
            );
          })}
        </CityViewList>
      </ContainerCityViewList>

      <FooterOption>
        <ButtonStepper onClick={nextStepper}>Próximo</ButtonStepper>
        <ButtonStepper onClick={prevStepper}>Voltar</ButtonStepper>
      </FooterOption>
    </form>
  );
}
