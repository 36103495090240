import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { createAppReducer } from "./CreateAppReducer";

function getKey(){
    const {pathname} = window.location;
    if(pathname.includes("/form/"))
        return btoa(pathname);
    return "root"
}

const rootPersistConfig = {
    key: getKey(),
    storage
};

const Reducers = combineReducers({ createAppReducer });

export default persistReducer(rootPersistConfig, Reducers);
