import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import logoImg from "../../assets/images/logo.png";
import apiCommercial from "../../services/apiCommercial";

import { Container, Content, Logo, LinkContact } from "./styles";

export default function NotPay() {
  const [customer, setCustomer] = useState(null);

  const { key } = useParams();

  const getCustomer = useCallback(async () => {
    const { data } = await apiCommercial.get(`/${key}/get_customer`);
    if (!Array.isArray(data.content)) setCustomer(data.content);
  }, [key]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  const getLink = useCallback(() => {
    if (!customer)
      return "https://api.whatsapp.com/send?1=pt_BR&phone=5585981190984";
    return `https://api.whatsapp.com/send?1=pt_BR&phone=5585981190984&text=${encodeURIComponent(
      `Olá sou ${customer.customer_name} do aplicativo ${customer.project_name} e estou com problemas financeiros para acessar o meu formulário do app.`
    )}`;
  }, [customer]);

  return (
    <Container>
      <Content>
        <Logo src={logoImg} alt="Logo" />
        <h2>PAGAMENTO NÃO REALIZADO</h2>
        <p>Por favor entre em contato com o financeiro.</p>
        <LinkContact href={getLink()}>Entrar em contato</LinkContact>
      </Content>
    </Container>
  );
}
