import styled, {createGlobalStyle} from 'styled-components';
import { shade } from 'polished';

export const GlobalStyle = createGlobalStyle`
  * {
  font-family: "Open Sans", sans-serif;
}

body {
  background-color: rgb(249, 255, 252);
  display: flex;
  flex-direction: column;
}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
`;

export const ContentPresentationHome = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 0;
`;

export const ContentPresentationHomeDiv = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
`;

export const ContentPresentationHomeH4 = styled.h4`
  font-weight: 600;
  color: var(--main-color);
  font-size: 25pt;
  display: block;
  margin-bottom: 30px;
`;

export const ContentPresentationHomeP = styled.p`
  font-weight: 300;
  margin-bottom: 40px;
  font-size: 13pt;
`;

export const ContentPresentationHomeImg = styled.img`
  width: 50%;
`;

export const ContentPresentationHomeA = styled.div`
  background-color: var(--main-color);
  color: #fff;
  border: none;
  width: -moz-fit-content;
  width: 70%;
  padding: 10px;
  font-size: 13pt;
  border-radius: 7px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;

  &:hover {
    background-color: ${shade(0.3, 'rgb(38, 68, 102)')};
  }
`;
