import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

export const ContactSupportViewTitleLeftContent = styled.p`
  font-size: 18pt;
  font-weight: 600;
  text-align: left;
  margin: 10px 0;
`;

export const ContactSupportViewDescriptionLeftContent = styled.p`
  text-align: left;
`;

export const ContactSupportViewInputContent = styled.input`
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 50%;
`;

export const PreLoader = styled(CircularProgress)`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 204px;
  right: 0;
  margin: auto;
`;
