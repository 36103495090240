export const CHANGE_STEPPER_CREATE_APP = "CHANGE_STEPPER_CREATE_APP";
export const CHANGE_SEND_DATA = "CHANGE_SEND_DATA";
export const SET_CITIES = "SET_CITIES";
export const SET_SUPPORT = "SET_SUPPORT";
export const SET_NAME_APP_CUSTOMER = "SET_NAME_APP_CUSTOMER";
export const SET_LOGO_APP_CUSTOMER = "SET_LOGO_APP_CUSTOMER";
export const SET_PREVIEW_LOGO_APP_CUSTOMER = 'SET_PREVIEW_LOGO_APP_CUSTOMER';
export const SET_NAME_APP_DRIVER = "SET_NAME_APP_DRIVER";
export const SET_LOGO_APP_DRIVER = "SET_LOGO_APP_DRIVER";
export const SET_PREVIEW_LOGO_APP_DRIVER = 'SET_PREVIEW_LOGO_APP_DRIVER';
export const CHANGE_COLOR_APP_CUSTOMER = "CHANGE_COLOR_APP_CUSTOMER";
export const CHANGE_COLOR_APP_DRIVER = "CHANGE_COLOR_APP_DRIVER";

const INITIAL_STATE = {
   stepper: 0,
   sendData: false,
   app: {
      appCustomer: {
         name: '',
         logo: null,
         previewLogo: null,
         colors: {
            mainColor: '#1d4873',
            secondColor: '#91c73e',
            mainTextColor: '#ffffff',
            secondTextColor: '#000000',
         },
      },
      appDriver: {
         name: '',
         logo: null,
         previewLogo: null,
         colors: {
            mainColor: '#1d4873',
            secondColor: '#91c73e',
            mainTextColor: '#ffffff',
            secondTextColor: '#000000',
         },
      },
      cities: [],
      support: {
         phone: '',
         phoneWhatsApp: '',
         email: '',
      },
   },
};

export function createAppReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
     case CHANGE_STEPPER_CREATE_APP: {
        return { ...state, stepper: action.value };
     }
     case CHANGE_SEND_DATA: {
        return { ...state, sendData: action.value };
     }
     case SET_CITIES: {
        return { ...state, app: { ...state.app, cities: action.value } };
     }
     case SET_SUPPORT: {
        return { ...state, app: { ...state.app, support: action.value } };
     }
     case SET_NAME_APP_CUSTOMER: {
        return {
           ...state,
           app: {
              ...state.app,
              appCustomer: { ...state.app.appCustomer, name: action.value },
           },
        };
     }
     case SET_LOGO_APP_CUSTOMER: {
        return {
           ...state,
           app: {
              ...state.app,
              appCustomer: { ...state.app.appCustomer, logo: action.value },
           },
        };
     }
     case SET_PREVIEW_LOGO_APP_CUSTOMER: {
        return {
           ...state,
           app: {
              ...state.app,
              appCustomer: { ...state.app.appCustomer, previewLogo: action.value },
           },
        };
     }
     case SET_NAME_APP_DRIVER: {
        return {
           ...state,
           app: {
              ...state.app,
              appDriver: { ...state.app.appDriver, name: action.value },
           },
        };
     }
     case SET_LOGO_APP_DRIVER: {
        return {
           ...state,
           app: {
              ...state.app,
              appDriver: { ...state.app.appDriver, logo: action.value },
           },
        };
     }
     case SET_PREVIEW_LOGO_APP_DRIVER: {
        return {
           ...state,
           app: {
              ...state.app,
              appDriver: { ...state.app.appDriver, previewLogo: action.value },
           },
        };
     }
     case CHANGE_COLOR_APP_CUSTOMER: {
        return {
           ...state,
           app: {
              ...state.app,
              appCustomer: { ...state.app.appCustomer, colors: action.value },
           },
        };
     }
     case CHANGE_COLOR_APP_DRIVER: {
        return {
           ...state,
           app: {
              ...state.app,
              appDriver: { ...state.app.appDriver, colors: action.value },
           },
        };
     }
     default: {
        return state;
     }
  }
}
