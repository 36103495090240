import React from 'react';

import { Container } from './styles';

export default function Select(props) {
    return (
        <Container>
            <label htmlFor={props.name}>{props.label}</label>
            <select value="" id={props.name} {...props}>
                <option value={props.default.value} key={props.default.value}>{props.default.label}</option>
                {props.options.map(option => {
                    return <option key={option.value} value={option.value}>{option.label}</option>
                })}
            </select>
        </Container>
    );
}