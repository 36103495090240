import React from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

import InputMaskPhone from "../../InputMaskPhone";

import {
  ContactSupportViewTitleLeftContent,
  ContactSupportViewDescriptionLeftContent,
  ContactSupportViewInputContent,
  PreLoader
} from "./styles";

import ButtonStepper from "../../ButtonStepper";
import FooterOption from "../../FooterOption";

import { changeSendData, changeStepper, setSupport } from "../../../action/CreateApp";

import { useSelector, useDispatch } from "react-redux";
import api from "../../../services/api";

export default function ContactSupportView() {
  const { key } = useParams();
  const { phoneWhatsApp, phone, email } = useSelector(
    (state) => state.createAppReducer.app.support
    );
    
  const { app, sendData } = useSelector((state) => state.createAppReducer);
  
  const appCustomer = useSelector((state) => state.createAppReducer.app.appCustomer);
  const appDriver = useSelector((state) => state.createAppReducer.app.appDriver);
  
  const stepperActive = useSelector((state) => state.createAppReducer.stepper);
  const dispatch = useDispatch();

  function handleSubmitForm() {
    dispatch(changeSendData(true));

    const formData = new FormData();
    formData.append("form_key", key);
    formData.append("name_app_client",app.appCustomer.name);
    formData.append("logo_app_client", app.appCustomer.logo);
    formData.append("primary_color_client", app.appCustomer.colors.mainColor);
    formData.append("auxiliary_primary_color_client",app.appCustomer.colors.mainTextColor);
    formData.append("secondary_color_client", app.appCustomer.colors.mainColor);
    formData.append("auxiliary_secondary_color_client", app.appCustomer.colors.mainTextColor);
    formData.append("name_app_driver", app.appDriver.name);
    formData.append("logo_app_driver", app.appDriver.logo);
    formData.append("primary_color_driver", app.appDriver.colors.mainColor);
    formData.append("auxiliary_primary_color_driver", app.appDriver.colors.mainTextColor);
    formData.append("secondary_color_driver",  app.appDriver.colors.mainColor);
    formData.append("auxiliary_secondary_color_driver", app.appDriver.colors.mainTextColor);
    formData.append("cities", JSON.stringify(app.cities));
    formData.append("phone", app.support.phone);
    formData.append("whatsapp", app.support.phoneWhatsApp);
    formData.append("email",app.support.email);

    api.post("/configapp", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: "json",
    })
    .then((response) => {
      dispatch(changeStepper(stepperActive + 1));
      dispatch(changeSendData(false));  
    })
    .catch((error) => {
      dispatch(changeSendData(false));  
      swal("Error ao finalizar cadastro, consulte os administradores", "", "warning");
    }).finally(() => {
      dispatch(changeSendData(false)); 
    }); 
  }

  async function nextStepper(event) {
    event.preventDefault();

    if (!appCustomer.logo.name || !appDriver.logo.name) {
      return swal("Adicione as logos", "", "warning").then(() => dispatch(changeStepper(stepperActive - 4)));
    }
    
    if (phone.length <= 0) {
      return swal("Informe o telefone!", "", "warning");
    }

    if (phoneWhatsApp.length <= 0) {
      return swal("Informe o WhatsApp!", "", "warning");
    }

    if (email.length <= 0) {
      return swal("Informe o E-mail", "", "warning");
    } 

    event.target.disabled = true;

    handleSubmitForm(); 
    
    event.target.disabled = false;
  }

  function prevStepper(event) {
    event.preventDefault();
    dispatch(changeStepper(stepperActive - 1));
  }
  
  if (sendData)
    return (
      <PreLoader />
    );

  return (
    <form>
      <ContactSupportViewTitleLeftContent>
        Contato de suporte para clientes
      </ContactSupportViewTitleLeftContent>
      <ContactSupportViewDescriptionLeftContent>
        Telefone
      </ContactSupportViewDescriptionLeftContent>
      <InputMaskPhone
        mask="(99) 99999-9999"
        value={phone}
        onChange={(event) => {
          dispatch(
            setSupport({ email, phoneWhatsApp, phone: event.target.value })
          );
        }}
      />

      <ContactSupportViewDescriptionLeftContent>
        WhatsApp
      </ContactSupportViewDescriptionLeftContent>
      <InputMaskPhone
        mask="(99) 99999-9999"
        value={phoneWhatsApp}
        onChange={(event) => {
          dispatch(
            setSupport({ email, phone, phoneWhatsApp: event.target.value })
          );
        }}
      />

      <ContactSupportViewDescriptionLeftContent>
        E-mail
      </ContactSupportViewDescriptionLeftContent>
      <ContactSupportViewInputContent
        type="mail"
        placeholder="E-mail"
        value={email}
        onChange={(event) => {
          dispatch(
            setSupport({ phoneWhatsApp, phone, email: event.target.value })
          );
        }}
      />

      <FooterOption>
        <ButtonStepper onClick={nextStepper}>Próximo</ButtonStepper>
        <ButtonStepper onClick={prevStepper}>Voltar</ButtonStepper>
      </FooterOption>
    </form>
  );
}
