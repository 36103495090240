import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import backgroundMobile from "../../assets/images/moldura.png";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import {
  ContainerSimulator,
  ContentSimulator,
  ContainerButtons,
  ButtonArrow,
   ContentPhone
} from "./styles";

function SimulatorMobile({ links, onLoadView }, ref) {
  const iframe = useRef();
  const [active, setActive] = useState(0);

  useImperativeHandle(ref, () => ({
    getIframe: () => {
      return iframe.current;
    },
  }));

  function handleNext() {
    if (links.length === active + 1) return active;

    iframe.current.classList.add("active");
    setActive(active + 1);
  }

  function handleBack() {
    if (active === 0) return active;

    iframe.current.classList.add("active");
    setActive(active - 1);
  }

  useEffect(() => {
    if (iframe.current) {
      setTimeout(() => {
        iframe.current.classList.remove("active");
      }, 500);
    }
  }, [active]);

  return (
    <ContentPhone src={backgroundMobile}>
      <ContainerSimulator>
        <ContentSimulator
          ref={iframe}
          src={links[active] || ""}
          onLoad={() => {
            if (onLoadView) onLoadView();
          }}
        />
        <ContainerButtons>
          <ButtonArrow type="button" onClick={handleBack}>
            <KeyboardArrowLeftIcon />
          </ButtonArrow>
          <ButtonArrow type="button" onClick={handleNext}>
            <KeyboardArrowRightIcon />
          </ButtonArrow>
        </ContainerButtons>
      </ContainerSimulator>
    </ContentPhone>
  );
}

export default forwardRef(SimulatorMobile);
