import styled from 'styled-components';

export const InfoAppViewTitleApp = styled.p`
  font-size: 12pt;
  font-weight: 600;
  text-align: left;
  margin: 10px 0;
`;

export const InfoAppViewDescriptionLeftContent = styled.p`
  text-align: justify;
  margin: 0 500px 0 0;
`;

export const InfoAppViewObservation = styled.label`
  color: #888;
  font-size: 10pt;
  text-align: flex-start;
  display: block;
  margin: 38px 0 8px 0;
`;

export const InfoAppViewTitle = styled.p`
  font-size: 12pt;
  font-weight: 600;
  text-align: left;
  margin: 20px 0 10px 0;
`;

export const InfoAppViewLine = styled.hr`
  margin: 23px 0;
`;