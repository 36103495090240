import React, { useEffect } from "react";

import LogoFinish from "../../../assets/images/logoFinish.png";

import { DescriptionFinish, ImgFinish } from "./styles";

export default function FinishView() {

  useEffect(() => {
    setTimeout(() => {
      localStorage.clear();
    }, 3000);
  }, []);

  return (
    <>
      <ImgFinish src={LogoFinish} />
      <DescriptionFinish>
        Parabéns, seus apps agora terão cores, suas marcas e logos, esse
        processo esta finalizado, agora nossa equipe entrará em contato pelo seu
        e-mail para marcar uma video chamada no melhor horário de sua agenda
        para que possamos te mostrar como gerenciar seu aplicativo, após isso
        poderá usar o suporte quantas vezes for necessário para tirar suas
        dúvidas. Somos parceiros e ajudaremos no seu crescimento e sucesso.{" "}
        <br />
        <br />
        Equipe 704Apps
      </DescriptionFinish>
    </>
  );
}
