import React from 'react';

import NoPhone from '../../assets/images/no-phone.svg';

import { InfoContainer, ImgNoPhone, Title } from './styles';

export default function InfoFormMobile() {
    return (
        <InfoContainer>
            <ImgNoPhone src={NoPhone} />
            <Title>Este formulário só pode ser acompanhado pelo computador.</Title>
        </InfoContainer>
    );
}