import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const Container = styled.div`
   display: flex;
   flex: 1;
   justify-content: center;
   align-items: center;
   flex-direction: column;
`;

export const Content = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   height: 90%;
   width: 50%;
   background-color: #fff;
   border-radius: 5px;
   overflow-y: auto;
`;

export const Header = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
   max-height: 165px;
   margin: 15px 0 20px 0;
   width: 92%;
`;

export const Information = styled.p`
   font-size: 11pt;
   text-align: justify;
`;

export const Title = styled.p`
   text-align: justify;
   font-size: 12pt;
   font-weight: bold;
`;

export const ContractContent = styled.iframe`
   flex: 1;
   width: 90%;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   overflow-y: scroll;
   & > p {
      text-align: justify;
   }
`;

export const Footer = styled.div`
   margin-top: 20px;
   display: flex;
   flex: 1;
   max-height: 140px;
   width: 90%;
   flex-direction: column;
   justify-content: center;
   align-items: center;
`;

export const ContainerCheckContract = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;

   & > label {
      font-size: 11pt;
      margin-left: 5px;
   }
`;

export const CheckBoxContract = styled.input.attrs(() => {
   return { type: 'checkbox' };
})`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0;
   z-index: 1;

   & + label {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 20px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
   }

   & + label:before {
      content: '';
      display: block;
      width: 17px;
      height: 17px;
      border: 1px solid #000;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.9;
      -webkit-transition: all 0.12s, border-color 0.08s;
      transition: all 0.12s, border-color 0.08s;
   }

   &:checked + label:before {
      width: 10px;
      top: -5px;
      left: 5px;
      border-radius: 0;
      opacity: 1;
      border-top-color: transparent;
      border-left-color: transparent;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
   }
`;

export const PreLoader = styled(CircularProgress)`
   position: absolute;
   bottom: 0;
   top: 0;
   left: 0;
   right: 0;
   margin: auto;
`;

export const ImgSuccess = styled.div`
   height: 300px;
   width: 100%;
   display: flex;
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
   background-image: url(${(props) => props.src});
`;

export const ContainerTextSuccess = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 600px;
   height: 180px;
`;
