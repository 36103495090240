import React, { useState } from 'react';
import swal from 'sweetalert';

import {
   InfoAppViewDescriptionLeftContent,
   InfoAppViewObservation,
   InfoAppViewTitleApp,
   InfoAppViewTitle,
   InfoAppViewLine,
} from './styles';

import PreviewInputFile from '../../PreviewInputFile';
import InputField from '../../InputField';
import ButtonStepper from '../../ButtonStepper';
import FooterOption from '../../FooterOption';

import {
   changeStepper,
   setNameAppDriver,
   setNameAppCustomer,
   setLogoAppDriver,
   setLogoAppCustomer,
   setPreviewLogoAppCustomer,
   setPreviewLogoAppDriver,
} from '../../../action/CreateApp';

import { useSelector, useDispatch } from 'react-redux';
import {
   ContentInputFile,
   DescriptionPreview,
   PreviewImageContent,
} from '../../PreviewInputFile/styles';

export default function InfoAppView() {
   const [errorNameCustomer, setErrorNameCustomer] = useState(false);
   const [errorNameDriver, setErrorNameDriver] = useState(false);

   const {
      appCustomer: {
         name: nameAppCustomer,
         logo: logoAppCustomer,
         previewLogo: logoAppCustomerPreview,
      },
      appDriver: { name: nameAppDriver, logo: logoAppDriver, previewLogo: logoAppDriverPreview },
   } = useSelector((state) => state.createAppReducer.app);

   const stepperActive = useSelector((state) => state.createAppReducer.stepper);

   const dispatch = useDispatch();

   function handleValidationImageWidthAndHeight(logo) {
      return new Promise((resolve) => {
         const image = new Image();

         image.src = URL.createObjectURL(logo);

         image.onload = function () {
            let validated = false;
            var height = this.height;
            var width = this.width;
            if (height != 512 || width != 512) {
               swal('A logo deve ter largura e altura igual a 512', '', 'warning');
               validated = true;
            }
            resolve(validated);
         };
      });
   }

   function nextStepper(event) {
      event.preventDefault();
      setErrorNameCustomer(false);
      setErrorNameDriver(false);

      if (nameAppCustomer.length <= 0) {
         setErrorNameCustomer(true);
         return swal('Informe o nome do aplicativo!', '', 'warning');
      }

      if (nameAppDriver <= 0) {
         setErrorNameDriver(true);
         return swal('Informe o nome do aplicativo!', '', 'warning');
      }


      if (!logoAppCustomer.name) return swal('Adicione a logo do aplicativo!', '', 'warning');

      dispatch(changeStepper(stepperActive + 1));
   }

   function prevStepper(event) {
      event.preventDefault();
      dispatch(changeStepper(stepperActive - 1));
   }

   return (
      <form>
         <InfoAppViewDescriptionLeftContent>
            Escolha um nome adequado para os seus aplicativos, estes nomes devem ser da forma que
            você quer que seus clientes e motoristas vejam ele nas lojas(Ex: PlayStore e Apple).
         </InfoAppViewDescriptionLeftContent>

         <InfoAppViewTitle>Logo App Cliente*</InfoAppViewTitle>
         <PreviewImageContent
            style={{
               backgroundImage: `url(${logoAppCustomerPreview})`,
               backgroundSize: 'cover',
               backgroundPosition: 'center',
            }}
         >
            <DescriptionPreview>
               {logoAppCustomerPreview ? '' : 'Adicionar Logo'}
            </DescriptionPreview>
            <ContentInputFile
               accept='.png'
               type='file'
               onChange={async (event) => {
                  const file = event.target.files[0];
                  
                  if (await handleValidationImageWidthAndHeight(file)) return;

                  dispatch(setLogoAppCustomer(file));
                  dispatch(setPreviewLogoAppCustomer(URL.createObjectURL(file)));
               }}
            />
         </PreviewImageContent>

         <InfoAppViewTitleApp>Nome do aplicativo para o Cliente*</InfoAppViewTitleApp>
         <InputField
            value={nameAppCustomer}
            error={errorNameCustomer}
            placeholder='Ex: 704Apps Cliente'
            onChange={(event) => {
               dispatch(setNameAppCustomer(event.target.value));
            }}
         />
         <InfoAppViewLine />

         <InfoAppViewTitle>Logo App Motorista*</InfoAppViewTitle>
         <PreviewImageContent
            style={{
               backgroundImage: `url(${logoAppDriverPreview})`,
               backgroundSize: 'cover',
               backgroundPosition: 'center',
            }}
         >
            <DescriptionPreview>{logoAppDriverPreview ? '' : 'Adicionar Logo'}</DescriptionPreview>
            <ContentInputFile
               accept='.png'
               type='file'
               onChange={async (event) => {
                  const file = event.target.files[0];

                  if (await handleValidationImageWidthAndHeight(file)) return;
                  dispatch(setLogoAppDriver(file));
                  dispatch(setPreviewLogoAppDriver(URL.createObjectURL(file)));
               }}
            />
         </PreviewImageContent>

         <InfoAppViewTitleApp>Nome do aplicativo para o Motorista*</InfoAppViewTitleApp>
         <InputField
            value={nameAppDriver}
            error={errorNameDriver}
            placeholder='Ex: 704Apps Motorista'
            onChange={(event) => {
               dispatch(setNameAppDriver(event.target.value));
            }}
         />
         <InfoAppViewObservation>
            IMPORTANTE: Leve em consideração letras maiúsculas e minúsculas. <br />
            Todos os Campos com * são obrigatórios.
         </InfoAppViewObservation>

         <FooterOption>
            <div>
               <ButtonStepper onClick={prevStepper}>Voltar</ButtonStepper>
               <ButtonStepper onClick={nextStepper}>Próximo</ButtonStepper>
            </div>
         </FooterOption>
      </form>
   );
}
