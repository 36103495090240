import React from "react";
import NavbarHome from "../../components/NavBarHome";
import {
  GlobalStyle,
  Container,
  ContentPresentationHome,
  ContentPresentationHomeDiv,
  ContentPresentationHomeH4,
  ContentPresentationHomeP,
  ContentPresentationHomeImg,
  ContentPresentationHomeA
} from "./style.js";

import bannerNoteMobi from "../../assets/images/banner-note-mobi.png";

export default function Home() {
  return (
    <>
    <GlobalStyle />
    <div>
      <NavbarHome />
      <Container>
        <ContentPresentationHome>
          <ContentPresentationHomeDiv>
            <ContentPresentationHomeH4>
              Tenha seu próprio aplicativo de mobilidade urbana
              </ContentPresentationHomeH4>
            <ContentPresentationHomeP>
              Vamos criar o seu aplicativo completo de mobilidade com a sua
              marca, suas cores e suas tarifas. Faça seu teste grátis!
          </ContentPresentationHomeP>
            <ContentPresentationHomeA href="#">Saiba mais</ContentPresentationHomeA>
          </ContentPresentationHomeDiv>
          <ContentPresentationHomeImg src={bannerNoteMobi} />
        </ContentPresentationHome>
      </Container>
    </div>
    </>
  );
}
