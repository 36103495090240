import styled from 'styled-components';

export const Container = styled.div`
    label {
        font-size: 1.4rem;
    }
    
    select {
        width: 100%;
        height: 3rem;
        margin-top: 0.8rem;
        border-radius: 6px;
        background: #f3f3f3;
        border: 1px solid #FFF;
        outline: 0;
        padding: 0 10px;
        font-size: 12pt;
    }
    
    & + & {
        margin-left: 25px;
    }

    &:focus-within::after {
        width: calc(100% - 3.2rem);
        height: 2px;
        content: "";
        background: #FFf;
        position: absolute;
        left: 1.6rem;
        right: 1.6rem;
        bottom: 0;
    }
`;


