import React from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import CreateApp from "../pages/CreateApp";
import Home from "../pages/Home";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/home" exact component={Home} />
        <Route path="/form/:key" exact component={CreateApp} />
        <Route path="*"  component={() => <h1>Not found</h1>} />
      </Switch>
    </BrowserRouter>
  );
}
