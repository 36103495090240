import styled from 'styled-components';

export const PreviewImageContent = styled.div`
  width: 100px;
  height: 100px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 10px 0;
  position: relative;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 8px #bebebe;
`;

export const ContentInputFile = styled.input`
  display: flex;
  align-items: center;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
`;

export const DescriptionPreview = styled.span`
  font-family: sans-serif;
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 9pt;
`;
