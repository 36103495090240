import React from "react";

import {
  NavBar,
  NavBarImg,
  NavBarUl,
  NavBarLi,
  NavBarA,
  NavBarButton,
} from "./styles.js";

import logo from "../../assets/images/logo.png";

export default function NavbarHome() {
  return (
    <NavBar>
      <NavBarImg src={logo} alt="logo" />
      <NavBarUl>
        <NavBarLi>
          <NavBarA>Como funciona</NavBarA>
        </NavBarLi>
        <NavBarLi>
          <NavBarA>Que somos</NavBarA>
        </NavBarLi>
        <NavBarLi>
          <NavBarButton type="button">Fale com o suporte</NavBarButton>
        </NavBarLi>
        <NavBarLi>
          <NavBarButton type="button">Acessar</NavBarButton>
        </NavBarLi>
      </NavBarUl>
    </NavBar>
  );
}
