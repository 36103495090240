import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import ButtonStepper from '../ButtonStepper';

import SuccessImage from '../../assets/images/success-form.png';

import apiCommercial from '../../services/apiCommercial';
import api from '../../services/api';

import {
   Container,
   Content,
   Information,
   Header,
   ContractContent,
   Footer,
   ContainerCheckContract,
   CheckBoxContract,
   Title,
   ImgSuccess,
   PreLoader,
   ContainerTextSuccess,
} from './styles';
import NotPay from '../NotPay';

export default function Contract({ active }) {
   const [checked, setChecked] = useState(false);
   const [paidStatus, setPaidStatus] = useState(null);
   const [formEnabled, setFormEnabled] = useState(null);
   const [loading, setLoading] = useState(true);

   const { key } = useParams();

   const URL_BASE_CONTRACT = `${apiCommercial.defaults.baseURL}/${key}/get_contract`;

   const getPaidStatus = useCallback(async () => {
      const response = await apiCommercial.get(`/${key}/get_paid_status`);

      if (response.data.error) {
         setPaidStatus(null);
         setFormEnabled(null);
         setLoading(false);
      }

      if (response.data.form_customer) {
         setPaidStatus(response.data.form_customer.paid_status);

         setLoading(false);
      }

      if (response.data.form_app) {
         setFormEnabled(response.data.form_app.form_key_enabled);

         setLoading(false);
      }
   }, [key]);

   const getDataCustomer = useCallback(async () => {
      const response = await api.get(`customer/${key}`);

      if(response.data.response) {
         const { name, email } = response.data.response;
         window.setCrispParams(name, email);
         return;
      }

      window.setCrispParams("Cliente", "teste@teste.com");
   }, [key]);

   useEffect(() => {
      getPaidStatus();
      getDataCustomer();
   });

   if (loading) {
      return <PreLoader />;
   }

   if (formEnabled == 0) {
      return (
         <>
            <Container>
               <Content style={{ fontFamily: 'arial', fontSize: 17 }}>
                  <ImgSuccess src={SuccessImage} />
                  <ContainerTextSuccess>
                     Suas informações ja estão salvas, qualquer alteração deve ser enviada ao e-mail
                     suporte@fabrica704.com.br e poderá ser cobrada como valor extra.
                  </ContainerTextSuccess>
               </Content>
            </Container>
         </>
      );
   }

   return paidStatus ? (
      <Container>
         <Content>
            <Header>
               <Title>
                  Bem vindo a 704Apps
                  <br />
                  <br />
               </Title>
               <Information>
                  Nesta etapa vamos personalizar seu aplicativo, colocar sua logo e suas cores, além
                  de outras informações úteis para seus clientes.
                  <br />
                  <br />
                  Para prosseguir, é necessário você ler seu contrato abaixo, veja com atenção todos
                  os tópicos, exigências e ao aceitar estará concordando com todos os termos.
                  <br />
                  <br />
                  Após o aceite do contrato prosseguiremos com a configuração.
               </Information>
            </Header>
            <ContractContent src={URL_BASE_CONTRACT} />
            <Footer>
               <ContainerCheckContract>
                  <CheckBoxContract
                     checked={checked}
                     onChange={(event) => {
                        setChecked(event.target.checked);
                     }}
                  />
                  <label>Li e aceito o Contrato.</label>
               </ContainerCheckContract>
               <ButtonStepper
                  type='button'
                  onClick={() => {
                     checked
                        ? active(checked)
                        : swal('Aceite o contrato para avançar!', '', 'info');
                  }}
               >
                  Avançar para o formulário
               </ButtonStepper>
            </Footer>
         </Content>
      </Container>
   ) : (
      <NotPay />
   );
}
