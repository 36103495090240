import React from "react";
import { useSelector, useDispatch } from "react-redux";

import logo from "../../../assets/images/logo.png";

import ButtonStepper from "../../ButtonStepper";
import FooterOption from "../../FooterOption";

import { changeStepper } from "../../../action/CreateApp";

import {
  InfoViewTitleContent,
  InfoViewDescriptionContent,
  InfoViewLogoContent,
  InfoViewContainer,
} from "./styles";

export default function InfoView() {
  const stepperActive = useSelector((state) => state.createAppReducer.stepper);

  const dispatch = useDispatch();

  function nextStepper(event) {
    event.preventDefault();
    dispatch(changeStepper(stepperActive + 1));
  }

  return (
    <form>
      <InfoViewContainer>
        <InfoViewLogoContent src={logo} />
        <InfoViewTitleContent>
          Informações sobre o Seu Aplicativo
        </InfoViewTitleContent>
        <InfoViewDescriptionContent>
          Em alguns instantes você vai nos dá informações suficientes para
          iniciar o seu projeto, lembre que baseado no que você está informando
          aqui será utilizado para fazer o seu aplicativo, então esteja como as
          informações prontas antes de iniciar este formulário.
        </InfoViewDescriptionContent>
        <FooterOption>
          <ButtonStepper onClick={nextStepper}>Próximo</ButtonStepper>
        </FooterOption>
      </InfoViewContainer>
    </form>
  );
}
