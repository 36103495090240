import React, { useRef, useState, useCallback} from 'react';

import {
    InfoAppViewContainerInput,
    InfoAppViewInputContent
} from './styles';

export default function InputField({error,value,onChange,placeholder}){
    const inputRef = useRef(null);
  
    const [isFocused, setIsFocused] = useState(false);
  
    const handleInputFocus = useCallback(() => {
      setIsFocused(true);
    }, []);
  
    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);
  
    return (
        <InfoAppViewContainerInput 
          isError={error} 
          isFocused={isFocused}
          >
            <InfoAppViewInputContent
              type="text"
              placeholder={placeholder}
              value={value}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              ref={inputRef}
              onChange={onChange}
            />
        </InfoAppViewContainerInput>
      );
  }