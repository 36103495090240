import styled from 'styled-components';
import { shade } from 'polished';

export const CityViewTitleLeftContent = styled.p`
  font-size: 18pt;
  font-weight: 600;
  text-align: left;
  margin: 10px 0;
`;

export const CityViewDescriptionLeftContent = styled.p`
  text-align: left;
`;

export const CityViewInputContent = styled.input`
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 50%;
`;

export const ContainerCityViewList = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 60%;

  legend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
  }
`;

export const ContainerSelects = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;

  button {
    margin: 40px 0 0 10px;
  }
`;

export const CityViewList = styled.ul`
  display: ${props=> props.active ? "flex" : "none"};
  flex-direction: column;
  list-style: none;
  width: 50%;
  margin-top: 15px;
  border: 1px solid #f2f2f2;
`;

export const CityViewItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 8px;

  & + & {
    margin-top: 10px;
  }

  & > span + span {
    color: #cc2900;
  }

  & > span + span:hover {
    color: ${shade(0.3,'#cc2900')};
  }
`;
