import {
  CHANGE_STEPPER_CREATE_APP,
  CHANGE_SEND_DATA,
  SET_CITIES,
  SET_SUPPORT,
  SET_NAME_APP_CUSTOMER,
  SET_LOGO_APP_CUSTOMER,
  SET_NAME_APP_DRIVER,
  SET_LOGO_APP_DRIVER,
  CHANGE_COLOR_APP_CUSTOMER,
  CHANGE_COLOR_APP_DRIVER,
  SET_PREVIEW_LOGO_APP_CUSTOMER,
  SET_PREVIEW_LOGO_APP_DRIVER
} from "../../reducers/CreateAppReducer";

export const changeStepper = value => ({
  type: CHANGE_STEPPER_CREATE_APP,
  value
});

export const changeSendData = value => ({
  type:  CHANGE_SEND_DATA,
  value
});

export const setListCities = value => ({
  type: SET_CITIES,
  value
});

export const setSupport = value => ({
  type: SET_SUPPORT,
  value
});

export const setNameAppCustomer = value => ({
  type: SET_NAME_APP_CUSTOMER,
  value
});

export const setLogoAppCustomer = value => ({
  type: SET_LOGO_APP_CUSTOMER,
  value
});

export const setPreviewLogoAppCustomer = (value) => ({
   type: SET_PREVIEW_LOGO_APP_CUSTOMER,
   value,
});

export const setNameAppDriver = value => ({
  type: SET_NAME_APP_DRIVER,
  value
});

export const setLogoAppDriver = value => ({
  type: SET_LOGO_APP_DRIVER,
  value
});

export const setPreviewLogoAppDriver = (value) => ({
   type: SET_PREVIEW_LOGO_APP_DRIVER,
   value,
});

export const changeColorAppCustomer = value => ({
  type: CHANGE_COLOR_APP_CUSTOMER,
  value
});

export const changeColorAppDriver = value => ({
  type: CHANGE_COLOR_APP_DRIVER,
  value
});
