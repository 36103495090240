import styled, { css } from 'styled-components';

export const InfoAppViewContainerInput = styled.div`
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #ccc;
  width: 30%;

  display: flex;
  align-items: center;

  ${(props) =>  
    props.isError && 
    css`
      border: 2px solid #cc2900;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border: 2px solid #0000ff;
    `}
`;

export const InfoAppViewInputContent = styled.input`
    flex: 1;
    background: transparent;
    border: 0;
    outline: 0;
`;
