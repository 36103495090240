import styled from "styled-components";


export const ContentPhone = styled.div`
    display:flex;
    justify-content: center;
    width: 15.5em;
    height: 33.75em;
    background-image: url(${(props)=> props.src});
    background-position:center;
    background-repeat:no-repeat;
    background-size: cover;
`;

export const ContainerSimulator = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 1.25em;
`;

export const ContentSimulator = styled.iframe`
    height: 25.62em;
    width: 13.75em;
    transition: 100ms all;
    opacity: 1;
    border: none;
    &.active{
        opacity:0;
    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    z-index:5;
`;

export const ButtonArrow = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    outline: 0;
    border: 0;
    color: #fff;
    cursor: pointer;
    margin-top: 0.62em;

    &:hover {
        background-color: #0000ff;
        color: #fff;
    }
`;
