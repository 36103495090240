import React from 'react';
import styled from 'styled-components';

import { Button } from './styles';

const style = { 
    enable: {
        borderRadius: "5px",
        padding: "10px",
        border: "none",
        outline: "0",
        backgroundColor: "#2d8a07",
        color: "#fff",
        cursor: "pointer",
        boxShadow: "0 0 10px #989898",
        margin: "0 5px 0",
    },

    disabled: {
        borderRadius: "5px",
        padding: "10px",
        border: "none",
        outline: "0",
        backgroundColor: "#5ff425",
        color: "#fff",
        cursor: "pointer",
        boxShadow: "0 0 10px #989898",
        margin: "0 5px 0",
    }
}

export default function ButtonStepper(props) {
    return <button style={!props.value ? style.enable : style.disabled} disabled={!props.value ? false : true} {...props}>{props.children}</button>;
}