import styled from 'styled-components';

export const ManagerStepperBoxContent = styled.div`
  display: block;
  background-color: #fff;
  margin: 10px;
  padding: 20px;
  font-family: sans-serif;
  border-radius: 5px;
`;

export const ManagerStepperContainerMain = styled.div`
  flex: 1;
  overflow-y: auto;
`;